<template>
  <navigation index="AdminHome" ref="navigation">
    <div :style="{ height: ContentHeight + 'px' }">
      <div class="topnavigation">
        管理首页
      </div>
   
    </div>
  </navigation>
</template>
<script  >
import axios from "../../../commons/AxiosMethod.js"
import { ElMessageBox } from "element-plus"
import * as echarts from "echarts" 
import navigation from "../../../components/Modules/AdminNavigation.vue";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      Report: {}, // 报表数据
      MaxOrder: {}, // 最大
      ProductSalesVolume: []
    }
  },
  name: "App",

  components: {navigation},
  mounted () {
    
  },
  methods: {
    GetProductSalesVolume () {
      axios.apiMethod(
        "/admin/ReportForms/GetProductSalesVolume",
        "get",
        {},
        (result) => {
          // 绘制图表
          var myChart = echarts.init(document.getElementById("productSalesVolume"))
          myChart.setOption(result.Data.ChartData)
        }
      )
    },
  
  }
}
</script>

<style scoped lang="less">
#dayChart,#productSalesVolume {
  width: 100%;
  height: 400px;
}

.line_item {
  height: 400px;
}

.content {
  height: 200px;
  padding: 20px;
  text-align: center;
  .content_title {
    height: 60px;
    font-size: 15pt;
  }
  .content_item {
    height: 130px;
    font-size: 30pt;
    font-weight: 900;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
